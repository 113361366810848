const credentials = {
  admin: {
    username: 'martensiiber@gmail.com', // Unit testing
    password: '123456', // Unit testing
  },
  demo: {
    username: 'kiirlugemine5@gmail.com', // Demo account authentication
    password: '', // Unit testing
  },
};

export default credentials;
