export const TIMER_INIT = 'TIMER_INIT';
export const TIMER_START = 'TIMER_START';
export const TIMER_PAUSE = 'TIMER_PAUSE';
export const TIMER_RESUME = 'TIMER_RESUME';
export const TIMER_RESET = 'TIMER_RESET';
export const TIMER_STOP = 'TIMER_STOP';

export const FETCH_TEXT_COLLECTIONS_START = 'FETCH_TEXT_COLLECTIONS_START';
export const FETCH_TEXT_COLLECTIONS_SUCCEEDED = 'FETCH_TEXT_COLLECTIONS_SUCCEEDED';
export const FETCH_TEXT_COLLECTIONS_FAILED = 'FETCH_TEXT_COLLECTIONS_FAILED';
export const FETCH_TEXTS_START = 'FETCH_TEXTS_START';
export const FETCH_TEXTS_SUCCEEDED = 'FETCH_TEXTS_SUCCEEDED';
export const FETCH_TEXTS_FAILED = 'FETCH_TEXTS_FAILED';
export const FETCH_READING_TEXT_START = 'FETCH_READING_TEXT_START';
export const FETCH_READING_TEXT_SUCCEEDED = 'FETCH_READING_TEXT_SUCCEEDED';
export const FETCH_READING_TEXT_FAILED = 'FETCH_READING_TEXT_FAILED';
export const SELECT_OWN_TEXT_START = 'SELECT_OWN_TEXT_START';
export const SELECT_OWN_TEXT_SUCCEEDED = 'SELECT_OWN_TEXT_SUCCEEDED';
export const SELECT_OWN_TEXT_FAILED = 'SELECT_OWN_TEXT_FAILED';
export const UNSELECT_TEXT = 'UNSELECT_TEXT';
export const SAVE_TEXT_START = 'SAVE_TEXT_START';
export const SAVE_TEXT_SUCCEEDED = 'SAVE_TEXT_SUCCEEDED';
export const SAVE_TEXT_FAILED = 'SAVE_TEXT_FAILED';
export const ANALYZE_TEXT_START = 'ANALYZE_TEXT_START';
export const ANALYZE_TEXT_SUCCEEDED = 'ANALYZE_TEXT_SUCCEEDED';
export const ANALYZE_TEXT_FAILED = 'ANALYZE_TEXT_FAILED';

export const TEXT_OPTION_UPDATED = 'TEXT_OPTION_UPDATED';
export const EXERCISE_OPTION_UPDATED = 'EXERCISE_OPTION_UPDATED';
export const SPEED_OPTION_UPDATED = 'SPEED_OPTION_UPDATED';
export const RESET_TEXT_OPTIONS = 'RESET_TEXT_OPTIONS';
export const RESET_EXERCISE_OPTIONS = 'RESET_EXERCISE_OPTIONS';

export const EXERCISE_SELECT = 'EXERCISE_SELECT';
export const MODIFICATION_CHANGED = 'MODIFICATION_CHANGED';
export const EXERCISE_PREPARING = 'EXERCISE_PREPARING';
export const EXERCISE_PREPARED = 'EXERCISE_PREPARED';
export const EXERCISE_STARTING = 'EXERCISE_STARTING';
export const EXERCISE_STARTED = 'EXERCISE_STARTED';
export const EXERCISE_START_FAILED = 'EXERCISE_START_FAILED';
export const EXERCISE_ATTEMPT_START = 'EXERCISE_ATTEMPT_START';
export const EXERCISE_FINISHING = 'EXERCISE_FINISHING';
export const READING_EXERCISE_FINISHED = 'READING_EXERCISE_FINISHED';
export const HELP_EXERCISE_FINISHED = 'HELP_EXERCISE_FINISHED';
export const EXERCISE_FINISH_FAILED = 'EXERCISE_FINISH_FAILED';
export const EXERCISE_RETRY = 'EXERCISE_RETRY';
export const EXERCISE_END = 'EXERCISE_END';

export const GENERATE_BLANK_EXERCISES_START = 'GENERATE_BLANK_EXERCISES_START';
export const GENERATE_BLANK_EXERCISES_SUCCEEDED = 'GENERATE_BLANK_EXERCISES_SUCCEEDED';
export const GENERATE_BLANK_EXERCISES_FAILED = 'GENERATE_BLANK_EXERCISES_FAILED';

export const TEST_PREPARING = 'TEST_PREPARING';
export const TEST_PREPARED = 'TEST_PREPARED';
export const TEST_PREPARE_FAILED = 'TEST_PREPARE_FAILED';
export const TEST_STARTING = 'TEST_STARTING';
export const TEST_STARTED = 'TEST_STARTED';
export const TEST_START_FAILED = 'TEST_START_FAILED';
export const TEST_ATTEMPT_START = 'TEST_ATTEMPT_START';
export const TEST_FINISHING = 'TEST_FINISHING';
export const TEST_FINISHED = 'TEST_FINISHED';
export const TEST_FINISH_FAILED = 'TEST_FINISH_FAILED';
export const TEST_END = 'TEST_END';

export const REEVALUATE_TEST_ATTEMPT_START = 'REEVALUATE_TEST_ATTEMPT_START';
export const REEVALUATE_TEST_ATTEMPT_SUCCEEDED = 'REEVALUATE_TEST_ATTEMPT_SUCCEEDED';
export const REEVALUATE_TEST_ATTEMPT_FAILED = 'REEVALUATE_TEST_ATTEMPT_FAILED';

export const FETCH_QUESTIONS_START = 'FETCH_QUESTIONS_START';
export const FETCH_QUESTIONS_SUCCEEDED = 'FETCH_QUESTIONS_SUCCEEDED';
export const FETCH_QUESTIONS_FAILED = 'FETCH_QUESTIONS_FAILED';
export const ADD_QUESTION_START = 'ADD_QUESTION_START';
export const ADD_QUESTION_SUCCEEDED = 'ADD_QUESTION_SUCCEEDED';
export const ADD_QUESTION_FAILED = 'ADD_QUESTION_FAILED';
export const CHANGE_QUESTION_START = 'CHANGE_QUESTION_START';
export const CHANGE_QUESTION_SUCCEEDED = 'CHANGE_QUESTION_SUCCEEDED';
export const CHANGE_QUESTION_FAILED = 'CHANGE_QUESTION_FAILED';
export const REMOVE_QUESTION_START = 'REMOVE_QUESTION_START';
export const REMOVE_QUESTION_SUCCEEDED = 'REMOVE_QUESTION_SUCCEEDED';
export const REMOVE_QUESTION_FAILED = 'REMOVE_QUESTION_FAILED';
export const ADD_ANSWER_START = 'ADD_ANSWER_START';
export const ADD_ANSWER_SUCCEEDED = 'ADD_ANSWER_SUCCEEDED';
export const ADD_ANSWER_FAILED = 'ADD_ANSWER_FAILED';
export const CHANGE_ANSWER_START = 'CHANGE_ANSWER_START';
export const CHANGE_ANSWER_SUCCEEDED = 'CHANGE_ANSWER_SUCCEEDED';
export const CHANGE_ANSWER_FAILED = 'CHANGE_ANSWER_FAILED';
export const REMOVE_ANSWER_START = 'REMOVE_ANSWER_START';
export const REMOVE_ANSWER_SUCCEEDED = 'REMOVE_ANSWER_SUCCEEDED';
export const REMOVE_ANSWER_FAILED = 'REMOVE_ANSWER_FAILED';

export const FETCH_USERS_START = 'FETCH_USERS_START';
export const FETCH_USERS_SUCCEEDED = 'FETCH_USERS_SUCCEEDED';
export const FETCH_USERS_FAILED = 'FETCH_USERS_FAILED';
export const ADD_USER_START = 'ADD_USER_START';
export const ADD_USER_SUCCEEDED = 'ADD_USER_SUCCEEDED';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const CHANGE_USER_START = 'CHANGE_USER_START';
export const CHANGE_USER_SUCCEEDED = 'CHANGE_USER_SUCCEEDED';
export const CHANGE_USER_FAILED = 'CHANGE_USER_FAILED';
export const FETCH_GROUPS_START = 'FETCH_GROUPS_START';
export const FETCH_GROUPS_SUCCEEDED = 'FETCH_GROUPS_SUCCEEDED';
export const FETCH_GROUPS_FAILED = 'FETCH_GROUPS_FAILED';
export const ADD_GROUP_START = 'ADD_GROUP_START';
export const ADD_GROUP_SUCCEEDED = 'ADD_GROUP_SUCCEEDED';
export const ADD_GROUP_FAILED = 'ADD_GROUP_FAILED';
export const CHANGE_GROUP_START = 'CHANGE_GROUP_START';
export const CHANGE_GROUP_SUCCEEDED = 'CHANGE_GROUP_SUCCEEDED';
export const CHANGE_GROUP_FAILED = 'CHANGE_GROUP_FAILED';

export const FETCH_EXERCISE_STATISTICS_START = 'FETCH_EXERCISE_STATISTICS_START';
export const FETCH_EXERCISE_STATISTICS_SUCCEEDED = 'FETCH_EXERCISE_STATISTICS_SUCCEEDED';
export const FETCH_EXERCISE_STATISTICS_FAILED = 'FETCH_EXERCISE_STATISTICS_FAILED';
export const FETCH_USER_EXERCISE_STATISTICS_START = 'FETCH_USER_EXERCISE_STATISTICS_START';
export const FETCH_USER_EXERCISE_STATISTICS_SUCCEEDED = 'FETCH_USER_EXERCISE_STATISTICS_SUCCEEDED';
export const FETCH_USER_EXERCISE_STATISTICS_FAILED = 'FETCH_USER_EXERCISE_STATISTICS_FAILED';
export const FETCH_GROUP_EXERCISE_STATISTICS_START = 'FETCH_GROUP_EXERCISE_STATISTICS_START';
export const FETCH_GROUP_EXERCISE_STATISTICS_SUCCEEDED = 'FETCH_GROUP_EXERCISE_STATISTICS_SUCCEEDED';
export const FETCH_GROUP_EXERCISE_STATISTICS_FAILED = 'FETCH_GROUP_EXERCISE_STATISTICS_FAILED';

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCEEDED = 'AUTH_SUCCEEDED';
export const AUTH_FAILED = 'AUTH_FAILED';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const REGISTER_START = 'REGISTER_START';
export const REGISTER_SUCCEEDED = 'REGISTER_SUCCEEDED';
export const REGISTER_FAILED = 'REGISTER_FAILED';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCEEDED = 'CHANGE_PASSWORD_SUCCEEDED';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const FETCH_USER_PROFILE_START = 'FETCH_USER_PROFILE_START';
export const FETCH_USER_PROFILE_SUCCEEDED = 'FETCH_USER_PROFILE_SUCCEEDED';
export const FETCH_USER_PROFILE_FAILED = 'FETCH_USER_PROFILE_FAILED';
export const SAVE_USER_PROFILE_START = 'SAVE_USER_PROFILE_START';
export const SAVE_USER_PROFILE_SUCCEEDED = 'SAVE_USER_PROFILE_SUCCEEDED';
export const SAVE_USER_PROFILE_FAILED = 'SAVE_USER_PROFILE_FAILED';
export const ACHIEVEMENTS_UPDATED = 'ACHIEVEMENTS_UPDATED';

export const SEND_FEEDBACK_START = 'SEND_FEEDBACK_START';
export const SEND_FEEDBACK_SUCCEEDED = 'SEND_FEEDBACK_SUCCEEDED';
export const SEND_FEEDBACK_FAILED = 'SEND_FEEDBACK_FAILED';
export const FETCH_FEEDBACK_START = 'FETCH_FEEDBACK_START';
export const FETCH_FEEDBACK_SUCCEEDED = 'FETCH_FEEDBACK_SUCCEEDED';
export const FETCH_FEEDBACK_FAILED = 'FETCH_FEEDBACK_FAILED';

export const SEND_PROBLEM_REPORT_START = 'SEND_PROBLEM_REPORT_START';
export const SEND_PROBLEM_REPORT_SUCCEEDED = 'SEND_PROBLEM_REPORT_SUCCEEDED';
export const SEND_PROBLEM_REPORT_FAILED = 'SEND_PROBLEM_REPORT_FAILED';
export const FETCH_PROBLEM_REPORTS_START = 'FETCH_PROBLEM_REPORTS_START';
export const FETCH_PROBLEM_REPORTS_SUCCEEDED = 'FETCH_PROBLEM_REPORTS_SUCCEEDED';
export const FETCH_PROBLEM_REPORTS_FAILED = 'FETCH_PROBLEM_REPORTS_FAILED';
export const RESOLVE_PROBLEM_REPORT_START = 'RESOLVE_PROBLEM_REPORT_START';
export const RESOLVE_PROBLEM_REPORT_SUCCEEDED = 'RESOLVE_PROBLEM_REPORT_SUCCEEDED';
export const RESOLVE_PROBLEM_REPORT_FAILED = 'RESOLVE_PROBLEM_REPORT_FAILED';

export const SEND_BUG_REPORT_START = 'SEND_BUG_REPORT_START';
export const SEND_BUG_REPORT_SUCCEEDED = 'SEND_BUG_REPORT_SUCCEEDED';
export const SEND_BUG_REPORT_FAILED = 'SEND_BUG_REPORT_FAILED';
export const FETCH_BUG_REPORTS_START = 'FETCH_BUG_REPORTS_START';
export const FETCH_BUG_REPORTS_SUCCEEDED = 'FETCH_BUG_REPORTS_SUCCEEDED';
export const FETCH_BUG_REPORTS_FAILED = 'FETCH_BUG_REPORTS_FAILED';
export const RESOLVE_BUG_REPORT_START = 'RESOLVE_BUG_REPORT_START';
export const RESOLVE_BUG_REPORT_SUCCEEDED = 'RESOLVE_BUG_REPORT_SUCCEEDED';
export const RESOLVE_BUG_REPORT_FAILED = 'RESOLVE_BUG_REPORT_FAILED';
